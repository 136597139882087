import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./queryClient";
import { router } from "./router";
import { Provider as MobXProvider } from "mobx-react";
import { stores } from "./stores";
import { appConfig, AppConfigContext } from "./config/app.config";

console.log("env", process.env);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <AppConfigContext.Provider value={appConfig}>
      <QueryClientProvider client={queryClient}>
        <MobXProvider {...stores}>
          <RouterProvider router={router} />
        </MobXProvider>
      </QueryClientProvider>
    </AppConfigContext.Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
